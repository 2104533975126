<template>
  <div style="text-align:left">
    <Card>
      <Row style="margin-top: 0px;">
        <Col span="15">
          <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="90">
            <FormItem label="名称" prop="name">
              <Input v-model.trim="formValidate.name" placeholder="请输入playbook名称"></Input>
            </FormItem>
            <FormItem label="内容" prop="content">
              <div class="editor-container">
                <yaml-editor  v-model="formValidate.content" />
              </div>
            </FormItem>
            <FormItem label="描述" prop="details">
              <Input v-model="formValidate.details" type="textarea" placeholder="请输入playbook描述" :autosize="{minRows: 5}"></Input>
            </FormItem>
            <FormItem>
              <Button type="primary" :loading="btn_loading" @click="handleSubmit('formValidate')" >提交</Button>
              <Button @click="routeReset" style="margin-left: 8px">返回</Button>
            </FormItem>
          </Form>
        </Col>
        <Col span="7" offset="1">
          <Alert show-icon>
            <h4 style="color: #ed4014">
              <p>1.当前页面使用使用使用playbook剧本功能。</p><br/>
              <p>2.使用剧本里面的主机必须填写为all。</p><br/>
              <p>3.支持获取环境变量的功能。</p><br/>
              <p>4.提供了yaml语法检查功能。</p><br/>
              <p>5.必须设置参数 gather_facts: no。</p>
            </h4>
          </Alert>
        </Col>
      </Row>
    </Card>
  </div>
</template>

<script>
    import {get,put} from "@/api/http.js";
    import YamlEditor from '@/components/YamlEditor/index.vue';
    export default {
      name: "updatePlaybook",
      components: { YamlEditor },
      data() {
        return {
          btn_loading: false,
          formValidate: {
            name: "",
            content: "",
            details: ""
          },
          ruleValidate: {
            name: [ {required: true, message: "The playbook name cannot be empty", trigger: "blur"}],
            content: [ {required: true, message: "The playbook content cannot be empty", trigger: "blur"}],
          },
        }
      },
      methods: {
        handleSubmit(value) {
          this.btn_loading = true;
          this.$refs[value].validate((valid) => {
            if (valid) {
              this.updateplaybook(this.$route.params.id,this.formValidate,this)
            } else {
              this.$Message.error('表单校验错误');
              this.btn_loading = false;
            }
          })
        },

        getPlaybook(id) {
          get(`/goansible/api/v1/ansible/playbook/detail?id=` + id)
              .then((resp)=>{
                if (resp.code === 0) {
                  this.formValidate = resp.data
                } else {
                  this.$Message.error(resp.msg);
                }
              }).catch(e=>{
            return e
          })
        },

        updateplaybook(id,data) {
          put(`/goansible/api/v1/ansible/playbook?id=`+ id,data)
              .then((resp)=>{
                if (resp.code === 0) {
                  this.$Message.success(resp.msg);
                  this.$router.go(-1)
                } else {
                  this.$Message.error(resp.msg);
                }
              })
              .catch((e)=>{
                return e
              })
        },

        routeReset() {
          this.$router.go(-1)
        }

      },
      mounted() {
        this.getPlaybook(this.$route.params.id)
      }
    }
</script>

<style scoped>

</style>
